/*
    DIV Center
*/

.centerDiv {
    position: absolute; 
    left: 50%;
    top: 50%; 
    transform: translate(-50%, -50%);
}
